exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bottles-drums-js": () => import("./../../../src/pages/bottles-drums.js" /* webpackChunkName: "component---src-pages-bottles-drums-js" */),
  "component---src-pages-civil-construction-fasteners-js": () => import("./../../../src/pages/civil-construction/fasteners.js" /* webpackChunkName: "component---src-pages-civil-construction-fasteners-js" */),
  "component---src-pages-civil-construction-finishing-materials-js": () => import("./../../../src/pages/civil-construction/finishing-materials.js" /* webpackChunkName: "component---src-pages-civil-construction-finishing-materials-js" */),
  "component---src-pages-civil-construction-flooring-js": () => import("./../../../src/pages/civil-construction/flooring.js" /* webpackChunkName: "component---src-pages-civil-construction-flooring-js" */),
  "component---src-pages-civil-construction-glass-js": () => import("./../../../src/pages/civil-construction/glass.js" /* webpackChunkName: "component---src-pages-civil-construction-glass-js" */),
  "component---src-pages-civil-construction-insulation-js": () => import("./../../../src/pages/civil-construction/insulation.js" /* webpackChunkName: "component---src-pages-civil-construction-insulation-js" */),
  "component---src-pages-civil-construction-materials-js": () => import("./../../../src/pages/civil-construction-materials.js" /* webpackChunkName: "component---src-pages-civil-construction-materials-js" */),
  "component---src-pages-civil-construction-miscellaneous-js": () => import("./../../../src/pages/civil-construction/miscellaneous.js" /* webpackChunkName: "component---src-pages-civil-construction-miscellaneous-js" */),
  "component---src-pages-civil-construction-roofing-materials-js": () => import("./../../../src/pages/civil-construction/roofing-materials.js" /* webpackChunkName: "component---src-pages-civil-construction-roofing-materials-js" */),
  "component---src-pages-civil-construction-test-js": () => import("./../../../src/pages/civil-construction-test.js" /* webpackChunkName: "component---src-pages-civil-construction-test-js" */),
  "component---src-pages-civil-construction-wood-js": () => import("./../../../src/pages/civil-construction/wood.js" /* webpackChunkName: "component---src-pages-civil-construction-wood-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookware-js": () => import("./../../../src/pages/cookware.js" /* webpackChunkName: "component---src-pages-cookware-js" */),
  "component---src-pages-garments-js": () => import("./../../../src/pages/garments.js" /* webpackChunkName: "component---src-pages-garments-js" */),
  "component---src-pages-glass-bottle-js": () => import("./../../../src/pages/glass-bottle.js" /* webpackChunkName: "component---src-pages-glass-bottle-js" */),
  "component---src-pages-hdpe-barrel-js": () => import("./../../../src/pages/hdpe-barrel.js" /* webpackChunkName: "component---src-pages-hdpe-barrel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pet-preforms-js": () => import("./../../../src/pages/pet-preforms.js" /* webpackChunkName: "component---src-pages-pet-preforms-js" */),
  "component---src-pages-plumbing-materials-fittings-js": () => import("./../../../src/pages/plumbing-materials/fittings.js" /* webpackChunkName: "component---src-pages-plumbing-materials-fittings-js" */),
  "component---src-pages-plumbing-materials-fixtures-js": () => import("./../../../src/pages/plumbing-materials/fixtures.js" /* webpackChunkName: "component---src-pages-plumbing-materials-fixtures-js" */),
  "component---src-pages-plumbing-materials-js": () => import("./../../../src/pages/plumbing-materials.js" /* webpackChunkName: "component---src-pages-plumbing-materials-js" */),
  "component---src-pages-plumbing-materials-pipe-js": () => import("./../../../src/pages/plumbing-materials/pipe.js" /* webpackChunkName: "component---src-pages-plumbing-materials-pipe-js" */),
  "component---src-pages-plumbing-materials-sealing-joining-compounds-js": () => import("./../../../src/pages/plumbing-materials/sealing-joining-compounds.js" /* webpackChunkName: "component---src-pages-plumbing-materials-sealing-joining-compounds-js" */),
  "component---src-pages-plumbing-materials-tools-js": () => import("./../../../src/pages/plumbing-materials/tools.js" /* webpackChunkName: "component---src-pages-plumbing-materials-tools-js" */),
  "component---src-pages-plumbing-materials-traps-drains-js": () => import("./../../../src/pages/plumbing-materials/traps-drains.js" /* webpackChunkName: "component---src-pages-plumbing-materials-traps-drains-js" */),
  "component---src-pages-plumbing-materials-valves-js": () => import("./../../../src/pages/plumbing-materials/valves.js" /* webpackChunkName: "component---src-pages-plumbing-materials-valves-js" */),
  "component---src-pages-plumbing-materials-waste-disposal-systems-js": () => import("./../../../src/pages/plumbing-materials/waste-disposal-systems.js" /* webpackChunkName: "component---src-pages-plumbing-materials-waste-disposal-systems-js" */),
  "component---src-pages-plumbing-materials-water-heaters-js": () => import("./../../../src/pages/plumbing-materials/water-heaters.js" /* webpackChunkName: "component---src-pages-plumbing-materials-water-heaters-js" */),
  "component---src-pages-plumbing-materials-water-supply-systems-js": () => import("./../../../src/pages/plumbing-materials/water-supply-systems.js" /* webpackChunkName: "component---src-pages-plumbing-materials-water-supply-systems-js" */),
  "component---src-pages-sanitary-js": () => import("./../../../src/pages/sanitary.js" /* webpackChunkName: "component---src-pages-sanitary-js" */),
  "component---src-pages-stationery-js": () => import("./../../../src/pages/stationery.js" /* webpackChunkName: "component---src-pages-stationery-js" */),
  "component---src-pages-wood-marble-js": () => import("./../../../src/pages/wood-marble.js" /* webpackChunkName: "component---src-pages-wood-marble-js" */)
}

