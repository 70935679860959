import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle.min"
import "./src/style/style.scss";
import "./src/style/_variables.scss";
import "./src/style/responsive.scss";
import "././static/fonts/all.min.css";

// import 'aos/dist/aos.css';
// import AOS from 'aos';
// import 'aos/dist/aos.css';   

// export const onClientEntry = () => {
//     AOS.init({
//         duration: 100,
//     });
// };

import ReactDOM from "react-dom/client"

export const onRouteUpdate = ({ location }) => {
    // window.scrollTo(0, 0);
    if (!location.hash) {
        // window.scrollTo(0, 0);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        console.log("Scroll Position:", window.scrollY); // Debugging line
    }
};

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}